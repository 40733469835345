
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive
} from 'vue'
import {
  gantiPassword,
  lupaPassword
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers,
  email,
  sameAs,
  minLength
} from '@vuelidate/validators'
import {
  useStore
} from 'vuex'
import {
  useRoute,
  useRouter
} from 'vue-router'

const mustBeCool = (value: any) => {
  /* eslint-disable no-useless-escape */
  const regSpecialChar = new RegExp(/(?=.[!@#\$%^&*])/)
  return regSpecialChar.test(value)
}

const regatLeast1Numeric = (value: any) => {
  /* eslint-disable no-useless-escape */
  const regSpecialChar = new RegExp(/(?=.[0-9])/)
  return regSpecialChar.test(value)
}

export default {
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    const form = reactive({
      username: null,
      email: null,
      newPassword: null,
      validatePassword: null
    })
    const submitted = ref(false)
    const title = ref('Lupa Password')
    const titleButton = ref('Submit')

    const isForgot = () => {
      if (route.name === 'change-password' || route.name === 'change-password-ansurance') {
        title.value = 'Ubah Password'
        titleButton.value = 'Ubah Password'
        return false
      }
      return true
    }

    let rules

    if (route.name === 'change-password' || route.name === 'change-password-ansurance') {
      rules = {
        newPassword: {
          required: helpers.withMessage('Password Baru harus diisi', required),
          // minLength: helpers.withMessage('Password Baru minimal mengandung 6 karakter.', minLength(6))
          specialChar: helpers.withMessage('Password Baru harus ada spesial karakter (!@#\$%^&*)', mustBeCool),
          regexLeast1Numeric: helpers.withMessage('Password Baru harus ada angka', regatLeast1Numeric),
          minLength: helpers.withMessage(
            (ctx: any) => `Password Baru minimal mengandung ${ctx.$params.min} karakter`,
            minLength(8)
          )
        },
        validatePassword: {
          required: helpers.withMessage('Password Lama harus diisi', required),
          // minLength: helpers.withMessage('Password Lama Baru minimal mengandung 6 karakter.', minLength(6))
          // sameAs: helpers.withMessage('Validasi Password tidak cocok dengan Password Baru', sameAs('newPassword'))
        },
      }
    } else {
      rules = {
        username: {
          required: helpers.withMessage('Username harus diisi', required),
        },
        email: {
          required: helpers.withMessage('Email harus diisi', required),
          email: helpers.withMessage('Email harus berupa alamat email yang benar.', email)
        }
      }
    }

    const v$ = useVuelidate(rules, form)

    const checkResponse = (val: any) => {
      console.log('pass', val)
      if (val.error) {
        $toast.add({
          severity: 'error',
          detail: val.message,
          group: 'bc',
          life: 5000
        })
      } else {
        $toast.add({
          severity: 'success',
          detail: val.result.detail ?? val.result.Detail,
          group: 'bc',
          life: 5000
        })
        if (isForgot() === true) {
          router.push({
            name: 'home'
          })
        } else {
          router.push({ name: 'dashboard' })
        }
      }
      store.dispatch('hideLoading')
    }

    // console.log('store', store)

    const storeProcess = async () => {
      store.dispatch('showLoading')
      let response
      let data
      if (isForgot()) {
        data = {
          Username: form.username,
          Email: form.email
        }
        response = await lupaPassword(data)
      } else {
        data = {
          Guid: store.state.appActiveUser.guid,
          NewPassword: form.newPassword,
          OldPassword: form.validatePassword
        }
        response = await gantiPassword(data)
      }
      checkResponse(response)
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      storeProcess()
    }
    return {
      form,
      storeProcess,
      submitted,
      submitData,
      v$,
      router,
      isForgot,
      title,
      titleButton
    }
  }
}
